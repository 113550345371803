<template>
  <div class="curriculum-card">
    <div class="curriculum-info">
      <div class="curriculum-title mar-b-14 hei22">
        <span>课节名称：</span>
        <span>{{ content.handout_name }}</span>
      </div>
      <div class="mar-b-14 font-s-14 hei20 color666">
        <div v-if="content.stu_num != undefined">
          <span>学生人数：</span>
          <span>{{ content.stu_num }}</span>
        </div>
        <div v-else>
          <span>所属课程：</span>
          <span>{{ content.course_name ? content.course_name : "--" }}</span>
        </div>
      </div>
      <div class="mar-b-14 curriculum-time font-s-14 hei20 color666">
        <img
          src="@/assets/home/time-icon.png"
          class="time-icon mar-r-8 img-inline-block"
        />
        <span class="inline-block">上课时间：</span>
        <span class="mar-r-18 inline-block">{{ content.class_time_text }}</span>
        <span class="inline-block">课程时长：</span>
        <span class="inline-block" style="margin-right: 120px">{{
          (content.class_time_end - content.class_time_start) / 60000 + "分钟"
        }}</span>
        <img
          src="https://rts-1251489922.cos.ap-shanghai.myqcloud.com/img/tky-jsh-nor%402x.png"
          class="time-icon mar-r-8 img-inline-block"
        />
        <span>
          教室号：{{ content.serial }}（拓课云教室号）&nbsp;&nbsp;
          {{ content.id }}（RTS）
        </span>
      </div>
      <div class="font-s-14 hei20 color666">
        <span v-if="role.id == 1">
          老师密码：{{ content.teacher_pwd }} &nbsp;&nbsp;
        </span>
        <span v-if="role.id == 2">
          助教密码：{{ content.assistant_pwd }} &nbsp;&nbsp;
        </span>
        <span v-if="role.id == 3">
          巡课密码：{{ content.patrol_pwd }} &nbsp;&nbsp;
        </span>
      </div>
      <!--      <div class="font-s-14 hei20 color666">-->
      <!--        <span></span>-->
      <!--      </div>-->
    </div>
    <div class="curriculum-btn-group">
      <img
        class="curriculum-btn"
        src="@/assets/home/not-started.png"
        alt=""
        v-if="content.status == 0"
        @click="openTuoKeYun"
      />
      <img
        class="curriculum-btn"
        src="@/assets/home/in-class.png"
        alt=""
        v-if="content.status == 1"
        @click="openTuoKeYun"
      />
      <img
        class="curriculum-btn"
        src="@/assets/home/teaching-report.png"
        alt=""
        v-if="content.status == 2"
        @click="openXueQing"
      />
    </div>
  </div>
</template>

<script>
import { getInClassRoom, getAnalysisUrl } from "@/api/api.js";
export default {
  name: "CurriculumCard",
  props: {
    content: {},
  },
  created() {
    console.log(this.role.id);
  },
  data() {
    return {
      role: this.$store.state.role,
    };
  },
  methods: {
    openTuoKeYun() {
      let data = {
        serial: this.content.serial,
        usertype: "0", // 0 老师
        username: this.$store.state.userInfo.name,
        pid: this.$store.state.userInfo.id,
      };
      let role = this.$store.state.role.id;
      if (role == 2) {
        data.usertype = 1;
      } else if (role == 3) {
        data.usertype = 4;
      }
      getInClassRoom(data).then((res) => {
        // console.log(res)
        if (res.data.code == 0) {
          window.open(res.data.data);
        }
      });
    },
    openXueQing() {
      let data = {
        serial: this.content.serial,
      };
      getAnalysisUrl(data)
        .then((res) => {
          // console.log(res, 'ressss')
          if (res.data.code === 0) {
            window.open(res.data.data.viewUrl[0]);
          } else {
            this.$message.info("教学报告24小时内生成，耐心等待哦");
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.curriculum-card {
  /*height: 140px;*/
  width: 100%;
  background: #fff;
  padding-left: 24px;
  box-sizing: border-box;
  position: relative;
  border-radius: 5px;
  &:hover {
    box-shadow: 0 3px 22px -4px rgba($color: #3a3a3a, $alpha: 0.34);
  }
  // 所有课程信息
  .curriculum-info {
    padding: 25px 0;
    // 课节名称
    .curriculum-title {
      font-size: 16px;
      color: #333;
      font-weight: 600;
    }
    // 所属课程、
    .belonging {
      font-size: 14px;
    }
    .curriculum-time {
      .time-icon {
        width: 14px;
      }
    }
  }
  .curriculum-btn-group {
    .curriculum-btn {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
    }
  }
}
.mar-b-14 {
  margin-bottom: 14px;
}
.font-s-14 {
  font-size: 14px;
}
.hei20 {
  height: 20px;
  line-height: 20px;
}
.hei22 {
  height: 22px;
  line-height: 22px;
}
.mar-r-18 {
  margin-right: 18px;
}
.mar-r-8 {
  margin-right: 8px;
}
.color666 {
  color: #666;
}
.inline-block {
  display: inline-block;
}
.img-inline-block {
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
}
</style>
