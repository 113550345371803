export function formateDate(fmt, date) {
  let ret;
  let newDate = new Date(date);
  const opt = {
    "Y+": newDate.getFullYear().toString(), // 年
    "m+": (newDate.getMonth() + 1).toString(), // 月
    "d+": newDate.getDate().toString(), // 日
    "H+": newDate.getHours().toString(), // 时
    "M+": newDate.getMinutes().toString(), // 分
    "S+": newDate.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
}
